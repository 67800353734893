import { PartialPhoto } from './Photo';

export enum ModelGender {
  Male = 'male',
  Female = 'female'
}

export interface Model {
  id: number
  name: string
  avatar: string
  cover: string
  mobile_cover: string
  gender: ModelGender
  is_popular: boolean
  avatar_link: string
  cover_link: string
  mobile_cover_link: string
  videos_count: number
  photos: PartialPhoto[]
  created_at: string
}

export type PartialModel = Partial<Model>;
